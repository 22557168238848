import React from 'react';

const Footer = () => (
  <footer
    className="container bg-gray-200 mx-auto px-3 mt-48 mb-8 text-gray-800"
    style={{ padding: 20 }}
  >
    <div className="flex -mx-2">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Email</h2>
        <p className="mt-5">baila@kaybiconsultations.com</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Numero de Téléphone</h2>
        <p className="mt-5">+1 514 836 0694</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Adresse</h2>
        <p className="mt-5">7175 Avenue Louis Hebert, Unit 101, Montréal, QC H2E 2X3</p>
      </div>
      {/* <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <i className="bx bxl-twitter"></i>
            <a href="https://dev.to/changoman">Facebook</a>
          </li>
          <li>
            <a href="https://twitter.com/HuntaroSan">Twitter</a>
          </li>
          <li>
            <a href="https://github.com/codebushi/gatsby-starter-lander">GitHub</a>
          </li>
        </ul>
      </div> */}
    </div>
  </footer>
);

export default Footer;
