export default [
  {
    // title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      "Nous sommes tres satisfaits du siteweb crée par Kaybi. Recevoir les requêtes de nos clients n'a jamais été aussi facile",
    customerName: 'Sopromi',
    customerTitle: 'Le Directeur Général',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    // title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Rapide et élégant notre nouveau siteweb créer par Kaybi nous a rapporché de nos clients.',
    customerName: 'Blue Hygiene',
    customerTitle: 'Le Gérant',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    // title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Nous recommandons vivement les services offerts par Kaybi si vous penser a avoir une belle présence sur le web.',
    customerName: 'Mamdoo',
    customerTitle: 'Le Gérant',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];
