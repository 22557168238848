import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Là où le futur rencontre le présent
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Chez Kaybi, nous créons le logiciel qui va vous aider a réaliser les benefices des
            nouvelles technologies. Nous fournissons des solutions qui gèrent, modernisent et
            securisent votre entreprise à travers vos endpoints, infrastructures et dans le cloud.
            Nous vous aidons à conquêrir votre prochain défis avec confiance.
          </p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="lg:pb-20 lg:pt-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Pourquoi nous choisir ?</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Géstion</p>
              <p className="mt-4">Surveillance de toutes vos activités de manière proactive</p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Modernisation</p>
              <p className="mt-4">
                Augmenter l'efficacité de vos équipes en automatisant vos tâches quotidiennes.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Sécurisation</p>
              <p className="mt-4">Protegez vos données contre toutes les menaces</p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="services"
      reverseOrder
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Fourntirue de matériel informatique
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Que ce soit pour un usage personnel ou pour votre entreprise, nous vous équipons des
            meilleurs outils informatiques. Avec nos partenaires <strong>Samsung</strong> et{' '}
            <strong>Lenovo</strong>, nous couvrons une grande gamme de produits incluant mais pas
            seulement les ordinateurs, les tablettes, les écrans, les accessoires et beaucoup
            d'autres produits.
          </p>
        </div>
      }
      secondarySlot={<img src={require('../../assets/printer.png')} />}
    />
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Développement de logiciel sur mésure
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Que ce soit pour créer un logiciel en partant de rien, moderniser ou convertir des
            solutions existantes, nous pouvons vous aider à développer un logiciel sur mésure qui
            repondent a vos besoins spécifiques
          </p>
        </div>
      }
      secondarySlot={<img src={require('../../assets/service-1.png')} />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Développement d'applications mobiles
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Dans un monde de plus en plus numérisé et mobile, il est essentiel de garder vos
            utilisateurs connectés à votre entreprise grâce à des expériences mobiles immersives.
          </p>
        </div>
      }
      secondarySlot={<img src={require('../../assets/service-2.png')} />}
    />

    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Conception de site vitrine</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Qu'il s'agisse de la reconstruction d'un ancien site Web ou de la conception et du
            développement d'une nouvelle présence en ligne, nous travaillerons avec vous pour créer
            une solution personnalisée offrant les meilleurs résultats pour votre entreprise et vos
            clients.
          </p>
        </div>
      }
      secondarySlot={<img src={require('../../assets/service-3-1.png')} />}
    />

    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Solutions cloud</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Vous n'avez pas de budget pour créer ou maintenir un logiciel ? nous pouvons vous aider
            a trouver les meilleures solutions existantes pour vos besoins.
          </p>
        </div>
      }
      secondarySlot={<img src={require('../../assets/service-4.png')} />}
    />

    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Conseil et consultation</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Vous voulez digitaliser vos opérations mais vous ne savez pas par ou commencer ? Nos
            specialistes vont étudier vos besoins avec vous et vous sugerer une solution sur mesure
            conformement a votre budget.
          </p>
        </div>
      }
      secondarySlot={<img src={require('../../assets/service-5.png')} />}
    />
    <section id="stats" className="lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="de délais respectés" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="de disponibilité" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="de clients satisfait" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Ce que nos clients disent</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Prêt à développer votre entreprise ?</h3>
      <p className="mt-8 text-xl font-light">
        Contacter nous dès maintenant pour obtenir une consultation gratuite
      </p>
      {/* <p className="mt-8">
        <Button size="xl">Get Started Now</Button>
      </p> */}
    </section>
  </Layout>
);
