import React from 'react';

const LogoIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 750.000000 750.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,750.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M0 3750 l0 -3750 3750 0 3750 0 0 3750 0 3750 -3750 0 -3750 0 0
-3750z m4129 3216 c380 -51 712 -154 1054 -328 851 -434 1469 -1247 1657
-2177 47 -231 63 -391 64 -631 0 -232 -10 -356 -50 -575 -205 -1141 -1031
-2086 -2138 -2446 -884 -287 -1856 -168 -2655 326 -335 207 -671 524 -906 855
-424 598 -633 1351 -574 2071 71 877 486 1668 1164 2220 409 334 903 561 1420
653 123 22 226 36 370 50 85 8 486 -4 594 -18z"
      />
      <path
        d="M3483 5792 c-10 -6 -13 -137 -15 -576 l-3 -568 -222 259 c-313 364
-403 470 -585 689 l-161 194 -419 0 c-231 0 -418 -4 -416 -8 2 -5 233 -260
513 -566 281 -307 509 -562 507 -567 -2 -8 -133 -203 -873 -1294 -97 -143
-174 -263 -172 -267 2 -5 176 -8 387 -8 l383 0 20 23 c11 12 108 155 215 317
414 628 489 740 498 740 5 0 15 -8 22 -19 7 -10 78 -89 158 -175 l145 -157 5
-362 5 -362 675 3 c514 3 697 7 765 17 358 56 585 244 666 551 28 105 31 300
6 394 -37 143 -139 291 -254 370 l-49 34 86 85 c143 143 195 274 195 496 0
225 -57 369 -204 515 -118 117 -236 178 -434 223 -87 19 -127 21 -762 24 -368
2 -676 0 -682 -5z m1433 -130 c125 -30 218 -69 297 -126 291 -208 333 -668 84
-918 -46 -46 -84 -71 -168 -111 -59 -29 -108 -54 -109 -58 0 -3 24 -13 53 -23
163 -57 293 -158 354 -273 51 -95 67 -164 66 -288 -1 -300 -151 -517 -420
-610 -132 -46 -229 -55 -611 -55 l-352 0 2 183 3 182 350 5 c398 6 407 8 497
89 61 54 84 100 100 190 31 179 -48 332 -201 393 -53 22 -72 23 -401 28 l-345
5 -3 182 -2 181 317 4 c294 4 323 6 384 26 81 27 149 84 187 156 24 46 27 61
27 156 0 94 -3 110 -27 156 -15 27 -45 67 -67 87 -95 86 -153 97 -528 97
l-293 0 0 178 c0 98 3 182 8 186 4 5 167 6 362 3 305 -5 367 -8 436 -25z
m-2386 -84 c205 -247 691 -817 824 -968 l146 -165 0 -252 c0 -139 -2 -253 -4
-253 -2 0 -85 90 -186 200 -100 110 -184 200 -188 200 -4 0 -25 -28 -47 -63
-116 -183 -709 -1073 -719 -1079 -12 -8 -516 -8 -516 -1 0 5 68 107 330 493
69 102 179 265 245 363 66 97 186 275 267 394 l147 218 -32 35 c-19 19 -130
141 -248 270 -118 129 -313 342 -432 473 l-218 237 273 0 273 0 85 -102z
m1470 -1138 l0 -1240 -195 0 -195 0 0 1240 0 1240 195 0 195 0 0 -1240z m803
737 c40 -20 66 -42 85 -70 24 -36 27 -50 27 -127 0 -77 -3 -91 -27 -127 -30
-46 -87 -78 -167 -93 -30 -5 -175 -10 -322 -10 l-269 0 0 231 0 231 308 -4
307 -3 58 -28z m33 -1044 c22 -12 55 -37 71 -56 78 -89 63 -283 -26 -346 -67
-48 -94 -51 -432 -51 l-319 0 0 241 0 240 333 -3 c319 -3 334 -4 373 -25z"
      />
      <path
        d="M2260 2492 c0 -192 2 -351 4 -353 2 -2 36 -3 75 -1 l71 4 0 91 1 92
52 58 52 57 98 -149 99 -150 155 -3 c95 -2 158 1 163 7 4 6 15 34 25 63 l17
53 146 -3 147 -3 19 -55 c11 -30 21 -57 22 -58 4 -5 144 -5 149 0 4 4 -77 225
-231 631 l-26 67 -78 0 -78 0 -30 -77 c-16 -43 -73 -196 -128 -340 -54 -144
-101 -260 -104 -258 -3 2 -63 89 -134 194 l-129 190 79 87 c43 48 103 113 132
145 l54 59 -95 0 -95 0 -98 -117 c-55 -65 -118 -139 -141 -166 l-43 -48 0 166
0 165 -75 0 -75 0 0 -348z m1009 33 c23 -66 41 -123 41 -127 0 -5 -43 -8 -95
-8 l-94 0 47 135 c25 74 49 131 53 127 3 -4 25 -61 48 -127z"
      />
      <path
        d="M3498 2808 c10 -18 69 -122 131 -230 l111 -197 0 -120 0 -121 75 0
75 0 0 125 0 125 125 222 c69 122 125 223 125 225 0 2 -37 3 -82 3 l-83 0 -79
-150 c-43 -83 -81 -150 -85 -150 -3 0 -41 68 -84 150 l-78 150 -85 0 -86 0 20
-32z"
      />
      <path
        d="M4200 2491 c0 -230 3 -350 9 -353 6 -1 102 -1 214 2 200 5 204 5 244
32 72 47 103 151 69 232 -7 18 -30 46 -50 62 l-36 30 30 22 c61 46 78 145 37
223 -18 33 -37 51 -76 71 -53 28 -55 28 -246 28 l-193 0 -2 -349z m370 199
c30 -30 27 -83 -6 -109 -23 -18 -41 -21 -120 -21 l-94 0 0 75 0 75 100 0 c87
0 103 -3 120 -20z m9 -284 c28 -26 29 -88 1 -116 -17 -17 -33 -20 -125 -20
l-105 0 0 81 0 81 104 -4 c85 -2 108 -6 125 -22z"
      />
      <path d="M4870 2490 l0 -350 70 0 70 0 0 350 0 350 -70 0 -70 0 0 -350z" />
      <path d="M2190 2060 l0 -30 1453 2 1452 3 0 25 0 25 -1452 3 -1453 2 0 -30z" />
    </g>
  </svg>
);

export default LogoIcon;
