import React from 'react';
import Card from './Card';

const CustomerCard = ({
  customer: { title, content, customerImage, customerName, customerTitle }
}) => (
  <Card className="mb-8">
    <p className="text-xl font-semibold">{title}</p>
    <p className="mt-6">{content}</p>
    <div className="flex items-center mt-8">
      <img className="w-12 h-12 mr-4 rounded-full" src={customerImage} alt={customerName} />
      <div>
        <p>{customerName}</p>
        <p className="text-sm text-gray-600">{customerTitle}</p>
      </div>
    </div>
  </Card>
);

export default CustomerCard;
